export const testimonials = [
  {
    image: require("./../../images/testimonials/unilorin.png"),
    reviewername: "Unilorin Dam",
    position: "University of Ilorin, Ilorin",
    review:
      "I've had the pleasure of partnering with CPN on multiple projects, and each time they've delivered outstanding results. Their expertise in construction management and their ability to handle complex projects with ease is impressive. They are a reliable and trustworthy partner, and I look forward to continuing our successful collaborations in the future.",
  },
  {
    image: require("./../../images/testimonials/taico.png"),
    reviewername: "Building Infrastructures",
    position: "Thomas Adewumi University, Oko Irese",
    review:
      "I've had the pleasure of partnering with CPN on multiple projects, and each time they've delivered outstanding results. Their expertise in construction management and their ability to handle complex projects with ease is impressive. They are a reliable and trustworthy partner, and I look forward to continuing our successful collaborations in the future.",
  },
  {
    image: require("./../../images/testimonials/kwara.png"),
    reviewername: "Isapa Concrete Weir Smith",
    position: "Kwara State",
    review:
      "I've had the pleasure of partnering with CPN on multiple projects, and each time they've delivered outstanding results. Their expertise in construction management and their ability to handle complex projects with ease is impressive. They are a reliable and trustworthy partner, and I look forward to continuing our successful collaborations in the future.",
  },
  {
    image: require("./../../images/testimonials/kwara.png"),
    reviewername: "Tada-Shonga Irrigation Project",
    position: "Kwara State",
    review:
      "I've had the pleasure of partnering with CPN on multiple projects, and each time they've delivered outstanding results. Their expertise in construction management and their ability to handle complex projects with ease is impressive. They are a reliable and trustworthy partner, and I look forward to continuing our successful collaborations in the future.",
  },
  {
    image: require("./../../images/testimonials/niger_works.jpeg"),
    reviewername: "Tundun Funlani Access Road, Minna",
    position: "Niger State Ministry of Works",
    review:
      "I've had the pleasure of partnering with CPN on multiple projects, and each time they've delivered outstanding results. Their expertise in construction management and their ability to handle complex projects with ease is impressive. They are a reliable and trustworthy partner, and I look forward to continuing our successful collaborations in the future.",
  },
];
