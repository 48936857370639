import React from "react";

const projects = [
  {
    image: require("../../images/asphalt/1.jpg"),
    title: "Interior Work Avroko",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-1",
  },
  {
    image: require("../../images/asphalt/2.jpg"),
    title: "Vilters",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-2",
  },
  {
    image: require("../../images/asphalt/3.jpg"),
    title: "Industrial Design",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-3",
  },
  {
    image: require("../../images/asphalt/4.jpg"),
    title: "House Bluprint",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-4",
  },
  {
    image: require("../../images/asphalt/5.jpg"),
    title: "Modern Bathroom",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-5",
  },
  {
    image: require("../../images/asphalt/6.jpg"),
    title: "Bellevue Project",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-4",
  },
  {
    image: require("../../images/asphalt/7.jpg"),
    title: "Qatar Pavilion",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-3",
  },
  {
    image: require("../../images/asphalt/8.jpg"),
    title: "Museum",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-2",
  },
  {
    image: require("../../images/asphalt/9.jpg"),
    title: "Modern house",
    address: "Muscat, Sultanate of Oman",
    filter: "cat-1",
  },
];

var bgimg1 = require("./../../images/background/cross-line.png");

class AsphaltGallery extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }
  render() {
    return (
      <div className="section-full p-tb80 inner-page-padding">
        <div className="container">
          <ul className="masonry-outer mfp-gallery work-grid row clearfix list-unstyled">
            {projects.map((item, index) => (
              <div
                key={index}
                className={`masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30`}
              >
                <div className="sx-box image-hover-block">
                  <div className="sx-thum-bx">
                    <img src={item.image} alt="" />
                  </div>
                  <a className="mfp-link" href={item.image}>
                    <i className="fa fa-arrows-alt" />
                  </a>
                </div>
              </div>
            ))}
          </ul>
          {/* GALLERY CONTENT END */}
        </div>
      </div>
    );
  }
}

export default AsphaltGallery;
