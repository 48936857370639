import React from "react";

var bgimg1 = require("./../../images/background/first.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b80 bg-gray">
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-12">
                  <div className="about-home-2 about-home-cust">
                    <p>
                      Civil engineering services is a vast domain covering
                      planning, designing and construction of infrastructure
                      such as commercial and residential buildings;
                      transportation infrastructure including roads, bridges,
                      pipelines, railways; water distribution systems such as
                      dams, canals, sanitation; environmental infrastructure
                      like landscaping, city planning, parks; industrial
                      infrastructure; construction management; construction
                      engineering
                      <br />
                      We also deliver services in Hydropower and
                      Electromechanical Engineering, Borehole Drilling &
                      Development and a lot more. With tremendous growth of
                      economy all over the world, we implement cost-effective,
                      yet efficient civil engineering and construction
                      engineering practices in order to meet the mounting needs
                      while meeting budget and time constraints. We have been
                      involved in all this services for the last 29 years.
                    </p>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 ">
                  <div
                    className="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                    style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
