export const filters = [
  { label: "Building", filter: ".building" },
  { label: "Dam", filter: ".dam" },
  { label: "Irrigation", filter: ".irrigation" },
  { label: "Highways", filter: ".highway" },
  { label: "Power", filter: ".power" },
];

export const projects = [
  {
    image: require("./../../images/projects/portrait/pic1.png"),
    title: "Thomas Adewumi University Infrastructures",
    address: "Oko Irese, Kwara State, Nigeria",
    filter: "building",
    path: "/projects/thomas-adewumi",
    date: "2019",
    client: "Thomas Adewumi University",
    year: "--",
    images: ["thomas", 5],
    desc: "",
  },
  {
    image: require("./../../images/projects/portrait/pic2.png"),
    title: "Gimi Dam",
    address: "Zaria, Kaduna State, Nigeria",
    filter: "dam",
    path: "/projects/gidi-dam",
    date: "2001",
    client: "Federal Ministry of Power, Works & Housing",
    year: "--",
    images: ["gidi", 16],
    desc: "",
  },
  {
    image: require("./../../images/projects/portrait/pic3.png"),
    title: "Unilorin Dam",
    address: "Ilorin, Kwara State, Nigeria",
    filter: "dam",
    path: "/projects/unilorin-dam",
    date: "2005",
    client: "University of Ilorin",
    year: "--",
    images: ["unilorin", 4],
    desc: "Water reservoir to serve as water supply to the university of Ilorin Dam with reservoir capacity of 1.8 million cubic meters (MCM); 8.2 METER HIGH, 190 m long. Concrete gravity section spillway with ogee crest. USBR stilling basin type 3 with ski-jump.",
  },
  {
    image: require("./../../images/projects/portrait/pic4.png"),
    title: "Isapa Concrete Weir",
    address: "Isapa, Kwara State, Nigeria",
    filter: "dam",
    path: "/projects/isapa-weir",
    date: "2007",
    client: "Kwara State Government",
    year: "--",
    images: ["isapa", 13],
    desc: "",
  },
  {
    image: require("./../../images/projects/portrait/pic5.png"),
    title: "Tada-Shonga Irrigation Project",
    address: "Shonga, Kwara State, Nigeria",
    filter: "irrigation",
    path: "/projects/tada-shonga",
    date: "Ongoing",
    client: "Federal Ministry of Water Resources and Sanitation",
    year: "--",
    images: ["shonga", 21],
    desc: "The Tada-Shonga irrigation project is located by the bank of Niger River in the stretch of land between Shonga, Tada, and Tchiji villages. It is actually situated within the flood plain on the right bank of River Niger about 45km downstream of Jebba Dam. <br/>The Project is to demonstrate the methods of improved rice cultivation in the flood plain of the Niger River through the use of modern technology which the local farmers can adopt, utilize and effectively manage. The project after execution will boost food production; provide employment for the teeming youths and rural populace. It will ensure social-economic development of the host communities which, in turn empowers them, the state and our nation at large. <br/>Objectives of the Project: <ol style='margin-left:30px'><li>To protect a vast and highly fertile agricultural land on the flood plains of the River Niger.</li><li>Target Production of about 53,000 Metric Tons of rice annually through double cropping of rice during the rainy and dry seasons.</li><li>Full employment for more than 5,000 farming families and support for cottage industries in areas of storage, processing and marketing of rice.</li><li>Promote and sustain the federal government’s policy on Import substitution of rice.</li><li>Harnessing the country’s irrigation potentials.</li></ul>",
  },
  {
    image: require("./../../images/projects/portrait/pic6.png"),
    title: "700MW Zungeru Hydropower Project Access Road",
    address: "Zungeru, Niger State, Nigeria",
    filter: "highway",
    path: "/projects/zungeru-hydropower",
    date: "2019",
    client: "Sinohydro Consortium",
    year: "--",
    images: ["zungeru", 7],
    desc: "700MW Zungeru Hydropower Project Access is a missing piece that links the 700MW Zungeru Hydropower Project site to Federal highway Minna-Zungeru-Tegina road. The scope of the road includes 200mm Sub base course, 200mm Base Course, 60mm Binder and 40mm Wearing course carefully constructed with Efficient and quality control and assurance prioritized professionals. The Evidence of the quality work speaks for itself.",
  },
  {
    image: require("./../../images/projects/portrait/pic7.png"),
    title: "Tagwai",
    address: "Minna, Niger State, Nigeria",
    filter: "highway",
    path: "/projects/tagwai",
    date: "2021",
    client: "Niger State Ministry of Works",
    year: "--",
    images: ["tagwai", 8],
    desc: "",
  },
  {
    image: require("./../../images/projects/portrait/pic8.png"),
    title: "Tundun Fulani Access Road",
    address: "Minna, Niger State, Nigeria",
    filter: "highway",
    path: "/projects/tundun",
    date: "2021",
    client: "Niger State Ministry of Works",
    year: "--",
    images: ["tundun", 3],
    desc: "",
  },
  {
    image: require("./../../images/projects/portrait/pic9.png"),
    title: "Gloriana Apartment",
    address: "Abuja, Nigeria",
    filter: "building",
    path: "/projects/gloriana-apartment",
    date: "2015 & 2016",
    client: "Gloriana",
    year: "--",
    images: ["gloriana", 4],
    desc: "",
  },
  {
    image: require("./../../images/projects/portrait/pic10.png"),
    title: "Zungeru Township Road",
    address: "Zungeru, Niger State, Nigeria",
    filter: "highway",
    path: "/projects/zungeru-township",
    date: "October 10, 2022",
    client: "Niger State Ministry of Works",
    year: "--",
    images: ["zungeruroad", 11],
    desc: "",
  },
  {
    image: require("./../../images/projects/portrait/pic11.png"),
    title: "Oko Idofin Access Road",
    address: "Oko Idofin, Kwara State, Nigeria",
    filter: "highway",
    path: "/projects/oko-idofin",
    date: "October 10, 2022",
    client: "Kwara State Government",
    images: ["oko", 5],
    desc: "",
  },
];
