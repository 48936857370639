import React, { useEffect, useState } from "react";
import Navigation from "../Common/Navigation";
import { NavLink } from "react-router-dom";
import useSubmitContact from "../../hooks/useSubmitContact";

var bnr = require("./../../images/background/bg-map.png");

function Header() {
  const [state, setState] = useState({
    logo: require("./../../images/logo.png"),
    isSearchActive: false,
    isQuoteActive: false,
  });

  const [inputHandle, submitForm, errorObj, formObj] = useSubmitContact();

  const handleSearchToggle = () => {
    setState((prev) => ({
      ...prev,
      isSearchActive: !prev.isSearchActive,
    }));
  };

  const handleQuoteToggle = () => {
    setState((prev) => ({
      ...prev,
      isQuoteActive: !prev.isQuoteActive,
    }));
  };

  useEffect(() => {
    {
      const handleScroll = () => {
        const offset = window.scrollY;

        const stickyheader = document.querySelector(".sticky-header ");

        if (offset >= 100) {
          stickyheader.classList?.add("is-fixed");
          stickyheader.classList?.add("color-fill");
        } else {
          stickyheader.classList?.remove("is-fixed");
          stickyheader.classList?.remove("color-fill");
        }
      };

      window.addEventListener("scroll", handleScroll);

      window.updateTopMostParent = (logopath) => {
        this.setState({ logo: logopath });
      };
    }
  }, []);

  const isSearchActive = state.isSearchActive;
  const isQuoteActive = state.isQuoteActive;

  return (
    <>
      <header className="site-header header-style-1 nav-wide mobile-sider-drawer-menu">
        <div className="top-bar bg-gray">
          <div className="container">
            <div className="d-flex justify-content-end">
              <ul className="list-unstyled e-p-bx">
                <li>
                  <span>Mail us:</span>{" "}
                  <a href="mailto:info@cpnnigeria.com" className="list-dark">
                    info@cpnnigeria.com
                  </a>
                </li>
                <li>
                  <span>Call us:</span>
                  <a href="tel:i+2349132713992" className="list-dark">
                    +234 (0) 9132713992
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar header-left-gray-block bg-white">
            <div className="container clearfix">
              <div className="logo-header">
                <div className="logo-header-inner logo-header-one">
                  <NavLink to={"./"}>
                    <img src={state.logo} alt="Cpn Nigeria" />
                  </NavLink>
                </div>
              </div>
              {/* NAV Toggle Button */}
              <button
                id="mobile-side-drawer"
                data-target=".header-nav"
                data-toggle="collapse"
                type="button"
                className="navbar-toggler collapsed"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar-first" />
                <span className="icon-bar icon-bar-two" />
                <span className="icon-bar icon-bar-three" />
              </button>
              {/* EXTRA NAV */}
              <div className="extra-nav">
                <div className="extra-cell">
                  <div className="contact-slide-show">
                    <NavLink
                      to={"#"}
                      className="get-in-touch-btn from-top"
                      onClick={handleQuoteToggle}
                    >
                      Get in touch
                    </NavLink>
                  </div>
                </div>
              </div>
              {/* EXTRA Nav */}
              {/* MAIN NAVIGATION */}
              <div className="header-nav nav-dark navbar-collapse collapse justify-content-start collapse">
                <Navigation />
              </div>
              {/* CONTACT */}
              <div
                className="contact-slide-hide"
                style={{
                  backgroundImage: "url(" + bnr + ")",
                  right: isQuoteActive ? "0px" : "100%",
                }}
              >
                <div className="contact-nav">
                  <NavLink
                    to={"#"}
                    className="contact_close"
                    onClick={handleQuoteToggle}
                  >
                    ×
                  </NavLink>
                  <div className="contact-nav-form">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className=" contact-nav-info">
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-phone" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Phone number</h6>
                              <p>234 (0) 9132713992</p>
                            </div>
                          </div>
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-envelope" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Email address</h6>
                              <p>info@cpnnigeria.com</p>
                            </div>
                          </div>
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-map-marker" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Head Office</h6>
                              <p>
                                1b Tafawa Balewa Street, Ilorin GRA, Kwara State
                              </p>
                            </div>
                          </div>
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-map-marker" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Abuja Office</h6>
                              <p>
                                P.O Box 1132, No 44, Lord Lugard Street,
                                Asokoro, Abuja Garki, Abuja.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 ">
                        <div
                          className="contact-nav-field shadow-lg p-a30 bg-white"
                          style={{ backgroundImage: "url(" + bnr + ")" }}
                        >
                          <form
                            className="cons-contact-form2 form-transparent"
                            method="post"
                            action="#"
                          >
                            <div className="input input-animate">
                              <label htmlFor="name">Name</label>
                              <input
                                type="text"
                                name="username"
                                id="name"
                                value={formObj.name}
                                required
                                onChange={inputHandle("name")}
                              />
                              <span className="spin" />
                            </div>
                            <div className="input input-animate">
                              <label htmlFor="email">Email</label>
                              <input
                                type="email"
                                name="email"
                                id="email"
                                required
                                value={formObj.email}
                                onChange={inputHandle("email")}
                              />
                              <span className="spin" />
                            </div>
                            <div className="input input-animate">
                              <label htmlFor="Phone">Phone</label>
                              <input
                                type="text"
                                name="phone"
                                id="Phone"
                                required
                                value={formObj.phone}
                                onChange={inputHandle("phone")}
                              />
                              <span className="spin" />
                            </div>
                            <div className="input input-animate">
                              <label htmlFor="message">Textarea</label>
                              <textarea
                                name="message"
                                id="message"
                                required
                                value={formObj.desc}
                                onChange={inputHandle("desc")}
                              />
                              <span className="spin" />
                            </div>
                            {errorObj.error && (
                              <p className="text-red">{errorObj.message}</p>
                            )}
                            {errorObj.success && (
                              <p className="text-green">{errorObj.message}</p>
                            )}
                            {errorObj.isLoading && (
                              <p className="text-yellow">{errorObj.message}</p>
                            )}
                            <div className="text-left p-t10">
                              <button
                                type="button"
                                className="site-button-secondry btn-half "
                                onClick={submitForm}
                              >
                                <span> Submit Now</span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* SITE SEARCH */}
              <div id="search" className={isSearchActive ? "open" : null}>
                <span className="close" onClick={handleSearchToggle} />
                <form
                  role="search"
                  id="searchform"
                  action="/search"
                  method="get"
                  className="radius-xl"
                >
                  <div className="input-group">
                    <input
                      defaultValue=""
                      name="q"
                      type="search"
                      placeholder="Type to search"
                    />
                    <span className="input-group-btn">
                      <button type="button" className="search-btn">
                        <i className="fa fa-search arrow-animation" />
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
