import React from "react";
import { NavLink } from "react-router-dom";

const services = [
  {
    count: "01",
    title: "Building Construction",
    flaticon: "flaticon-skyline",
    description:
      "Crafting dreams into durable structures, our passion is your perfect space.",
  },
  {
    count: "02",
    title: "Civil Engineering",
    flaticon: "flaticon-blueprint",
    description:
      "Guided by innovation and precision, our civil engineering expertise shapes the world's infrastructure, ensuring a sustainable future for all.",
  },
  {
    count: "03",
    title: "Highway Construction",
    flaticon: "flaticon-architect",
    description:
      "Paving the way to smoother journeys, our highway construction blends excellence and safety for your transportation needs",
  },
  {
    count: "04",
    title: "Water Resources Engineering",
    flaticon: "flaticon-real-estate",
    description:
      "Harnessing nature's flow, we specialize in water resources engineering to safeguard, manage, and enhance our vital aquatic ecosystems",
  },
  {
    count: "05",
    title: "Hydropower & Electromechanical Engineering",
    flaticon: "flaticon-power-strip",
    description:
      "Empowering the world with clean energy, our hydropower and electromechanical engineering solutions drive sustainable progress",
  },
  {
    count: "06",
    title: "Borehole Drilling & Development",
    flaticon: "flaticon-ruler",
    description:
      "Digging deeper for your water needs, our borehole drilling and development services ensure a reliable source beneath your feet",
  },
];

var bgimg1 = require("./../../images/background/cross-line2.png");

class Services1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80  p-b50 bg-gray">
          <div className="section-content">
            <div className="container">
              {/* TITLE START */}
              <div className="section-head">
                <div className="sx-separator-outer separator-left">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                  >
                    <h3 className="sep-line-one ">All Services</h3>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              <div className="row">
                {services.map((item, index) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 m-b30"
                    key={index}
                  >
                    <div className="sx-icon-box-wraper  icon-count-2-outer">
                      <div className="icon-count-2 bg-white">
                        <span className="icon-count-number">{item.count}</span>
                        <div className="icon-xl inline-icon m-b5 scale-in-center">
                          <span className="icon-cell">
                            <i className={item.flaticon} />
                          </span>
                        </div>
                        <div className="icon-content">
                          <h4 className="sx-tilte">{item.title}</h4>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Services</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Services1;
