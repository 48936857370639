import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/background/first.jpg");
var bgimg2 = require("./../../images/about-slider/asphalt_1.png");

class About5 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b80 bg-gray">
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-12 ">
                  <div
                    className="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                    style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                  ></div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-12">
                  <div className="about-home-2 about-home-cust">
                    <h3 className="m-t0 m-b20 sx-tilte">What We Offer</h3>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <NavLink to="#">
                          <b>Bitumen Emulsion Production</b>
                        </NavLink>
                        <p>
                          we have completed several roads and bridges, of
                          varying complexity. Our services include detailed
                          engineering, construction and rehabilitation works.
                          <ul style={{ listStyle: "none" }}>
                            <li>Emulsion Prime Coat</li>
                            <li>Emulsion Tack Coat</li>
                          </ul>
                        </p>
                      </li>
                      <li>
                        <NavLink to="#">
                          <b>Asphalt Production</b>
                        </NavLink>
                        <p>
                          Our company is one of the Asphalt indigenous in
                          <ul style={{ listStyle: "none" }}>
                            <li>Binder Asphalt Course</li>
                            <li>Wearing Asphalt Course</li>
                          </ul>
                        </p>
                      </li>
                      <li>
                        <NavLink to="#">
                          <b>Granite Production</b>
                        </NavLink>
                        <p>
                          Our granite is meticulously crafted to not only meet
                          but exceed all of your stringent quality standards. We
                          take immense pride in the exceptional quality and
                          beauty of our granite, ensuring that it not only
                          enhances your project but also stands as a testament
                          to our commitment to excellence.
                        </p>
                      </li>
                      <li>
                        <NavLink to="#">
                          <b>Haulage Services</b>
                        </NavLink>
                        <p>
                          CPN boasts an extensive and impressive fleet of
                          haulage vehicles, comprising a wide array of
                          meticulously maintained trucks and trailers. Our
                          diverse and robust fleet stands as a testament to our
                          dedication to providing top-notch transportation
                          solutions for all your logistical needs. With CPN, you
                          can trust in our capacity to handle your cargo
                          efficiently and reliably, no matter the size or
                          complexity of the task at hand.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-full mobile-page-padding p-t80 p-b80 bg-gray">
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-2 about-home-cust">
                    <h3 className="m-t0 m-b20 sx-tilte">Production Plants</h3>
                    <ul className="list-angle-right anchor-line">
                      <li>
                        <NavLink to="#">
                          <b>Oyo Asphalt Plant</b>
                        </NavLink>
                        <p>
                          Km 7, Odo-Ona Elewe Road, Off Alao Akala Expressway,
                          Ibadan, Oyo State.
                        </p>
                      </li>
                      <li>
                        <NavLink to="#">
                          <b>Kwara Asphalt Plant</b>
                        </NavLink>
                        <p>
                          Km 14, Eyenkorin-Ballah Road, Ilorin, Kwara State.
                        </p>
                      </li>
                      <li>
                        <NavLink to="#">
                          <b>Abuja Asphalt Plant</b>
                        </NavLink>
                        <p>
                          Km 5, Abuja-Kaduna Expressway, Behind Zuma Rock, Abuja
                          FCT.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 ">
                  <div
                    className="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                    style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About5;
