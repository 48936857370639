import React from "react";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/background/second.jpg");

class About2 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b80 bg-gray">
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-12 ">
                  <div
                    className="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                    style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                  ></div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-12">
                  <div className="about-home-2">
                    <h3 className="m-t0 sx-tilte">
                      Construction Products Nigeria Limited{" "}
                    </h3>
                    <p>
                      was incorporated under the Company Laws of the Federal
                      Republic of Nigeria in April 5th 1994 as an Engineering
                      Construction Firm
                      <br />
                      The Company provides Engineering Construction Services in
                      Nigeria and West Africa Sub-region and develops indigenous
                      expertise in an area previously dominated by foreign
                      Companies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About2;
