import React from "react";
import { NavLink } from "react-router-dom";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import { projects, filters } from "../../assets/constants/projects";

var bnrimg = require("./../../images/banner/all.jpg");
var bgimg1 = require("./../../images/background/cross-line.png");

class Projects extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }

  componentDidUpdate(prevProps) {}
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner
            title="Our Projects"
            pagename="Our Projects"
            description="We conceptualize, plan, execute and handover projects which turns clients dream to reality and in turn benefit even the generation unborn."
            bgimage={bnrimg}
          />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 bg-white inner-page-padding">
            {/* GALLERY CONTENT START */}
            <div className="container">
              {/* Filter Nav START */}
              <div className="filter-wrap p-b30 text-center">
                <ul className="filter-navigation masonry-filter clearfix">
                  <li className="active">
                    <NavLink
                      to={"#"}
                      className="btn from-top"
                      data-filter="*"
                      data-hover="All"
                    >
                      All
                    </NavLink>
                  </li>
                  {filters.map((item, index) => (
                    <li key={index}>
                      <NavLink
                        to={"#"}
                        className="btn from-top"
                        data-filter={item.filter}
                      >
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              {/* Filter Nav END */}
              {/* GALLERY CONTENT START */}
              <ul className="masonry-outer mfp-gallery work-grid row clearfix list-unstyled">
                {projects.map((item, index) => (
                  <div
                    key={index}
                    className={`${item.filter} masonry-item col-lg-4  col-md-4 col-sm-6 m-b30`}
                  >
                    <div className="project-mas hover-shadow">
                      <div className="image-effect-one">
                        <img src={item.image} alt="" />
                        <div className="figcaption">
                          <a className="mfp-link" href={item.image}>
                            <i className="fa fa-arrows-alt" />
                          </a>
                        </div>
                      </div>
                      <div className="project-info p-a20 bg-gray">
                        <h4 className="sx-tilte m-tb0">
                          <NavLink to={item.path}>{item.title}</NavLink>
                        </h4>
                        <p>{item.description}</p>
                        <NavLink to={"#"}>
                          <i className="link-plus bg-primary" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                ))}
              </ul>
              {/* GALLERY CONTENT END */}
              <div
                className="text-center load-more-btn-outer"
                style={{ backgroundImage: "url(" + bgimg1 + ")" }}
              ></div>
            </div>
            {/* GALLERY CONTENT END */}
          </div>
          {/* SECTION CONTENT END  */}
        </div>

        <Footer />
      </>
    );
  }
}

export default Projects;
