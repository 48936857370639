import React from "react";
import Header4 from "../Common/Header4";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import WhatWeDo6 from "../Elements/WhatWeDo6";
import Services1 from "../Elements/Services1";

var bnrimg = require("./../../images/banner/all.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner
            title="Services"
            pagename="Services"
            description="Our range of services includes: Engineering design, Project planning, Technical studies, Project construction and Project Management."
            bgimage={bnrimg}
          />
          <WhatWeDo6 />
          <Services1 />
        </div>
        <Footer />
      </>
    );
  }
}

export default Service1;
