import React from "react";
import Header4 from "../Common/Header4";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import About2 from "../Elements/About2";
import About3 from "../Elements/About3";
import WhatWeDo6 from "../Elements/WhatWeDo6";
import Achievements1 from "../Elements/Achievements1";
import Team2 from "../Elements/Team2";
import Testimonials2 from "../Elements/Testimonials2";
import About4 from "../Elements/About4";
var bnrimg = require("./../../images/banner/all.jpg");

class About1 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner
            title="About Company"
            pagename="About"
            description="CPN is founded to provide engineering construction services without compromising standard
"
            bgimage={bnrimg}
          />
          <About2 />
          <About3 />
          <About4 bgcolor="bg-white" />
          <WhatWeDo6 />
          <Achievements1 />
          {/* <Team2 /> */}
          <Testimonials2 separatoralignment="separator-center" />
        </div>

        <Footer />
      </>
    );
  }
}

export default About1;
