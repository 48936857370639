import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { projects } from "../../assets/constants/projects";

var bgimg1 = require("./../../images/background/cross-line2.png");

class Projects6 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: false,
      center: false,
      items: 3,
      margin: 40,
      nav: true,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
          margin: 15,
        },
        640: {
          items: 2,
          margin: 15,
        },
        768: {
          items: 2,
          margin: 15,
        },
        991: {
          items: 3,
          margin: 15,
        },
        1200: {
          items: 3,
        },
      },
    };
    return (
      <>
        <div className="section-full p-tb80 bg-gray inner-page-padding">
          <div className="container">
            <div className="section-content">
              {/* TITLE START */}
              <div className="section-head">
                <div className="sx-separator-outer separator-left">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                  >
                    <h3 className="sep-line-one">Similar Project</h3>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              <div className="work-carousel-outer">
                <OwlCarousel
                  className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center p-lr80"
                  {...options}
                >
                  {projects
                    .filter((d) => d.title != this.props.title)
                    .map((item, index) => (
                      <div key={index} className="item">
                        <div className="project-mas m-a30">
                          <div className="image-effect-one">
                            <img src={item.image} alt="" />
                          </div>
                          <div className="project-info p-t20">
                            <h4 className="sx-tilte  m-t0">
                              <NavLink to={item.path}>{item.title}</NavLink>
                            </h4>
                            <p>{item.description}</p>
                            <NavLink to={"/project-detail1"}>
                              <i className="link-plus bg-primary" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Projects6;
