import React from "react";

class Switcher extends React.Component {
  constructor() {
    super();
    this.state = { stylePath: "./assets/css/skin/skin-2.css" };
  }

  render() {
    return (
      <>
        <link rel="stylesheet" type="text/css" href={this.state.stylePath} />
      </>
    );
  }
}

export default Switcher;
