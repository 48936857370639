import React, { useEffect, useState } from "react";
import Header4 from "../Common/Header4";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import Projects6 from "../Elements/Projects6";
import { projects } from "../../assets/constants/projects";
import { useParams, useLocation } from "react-router-dom";

var bnrimg = require("../../images/banner/all.jpg");

function ProjectDetail() {
  const [state, setState] = useState({ project: null });
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    const { path } = params;
    const project = projects.find(
      (d) => d.path.split("/").reverse()[0] == path
    );
    setState({ project });
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const project = projects.find(
      (d) => d.path.split("/").reverse()[0] == path.split("/").reverse()[0]
    );
    setState({ project });
  }, [location]);

  const project = state.project;

  if (project) {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner
            title={project.title}
            pagename={project.title}
            description=""
            bgimage={bnrimg}
          />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 inner-page-padding stick_in_parent">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7  sticky_column">
                  <div className="project-detail-containt">
                    <div className="bg-white text-black">
                      <h3>{project.title}</h3>
                      <p dangerouslySetInnerHTML={{ __html: project.desc }}></p>
                      <div className="product-block">
                        <ul>
                          <li>
                            <h4 className="m-b10">Date</h4>
                            <p>{project.date}</p>
                          </li>
                          <li>
                            <h4 className="m-b10">Client</h4>
                            <p>{project.title}</p>
                          </li>
                          <li>
                            <h4 className="m-b10">Project type</h4>
                            <p>
                              {project.filter
                                .toLowerCase()
                                .replace(/\b\w/g, (s) => s.toUpperCase())}
                            </p>
                          </li>
                          <li>
                            <h4 className="m-b10">Location</h4>
                            <p>{project.address}</p>
                          </li>
                        </ul>
                      </div>
                      <div className="m-b0">
                        <div className="sx-divider divider-1px  bg-black">
                          <i className="icon-dot c-square" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 control-height mfp-gallery">
                  <div className="project-detail-outer">
                    {Array.from(Array(project.images[1]).keys()).map((d) => (
                      <div className="project-detail-pic m-b30" key={d}>
                        <div className="sx-media">
                          <img
                            src={require(`./../../images/projects/all/${
                              project.images[0]
                            }${d + 1}.png`)}
                            alt=""
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END  */}
          <Projects6 title={project.title} />
        </div>

        <Footer />
      </>
    );
  }
}

export default ProjectDetail;
