import React from "react";

const achievements1 = [
  {
    title: "CAC Registration",
    description:
      "Construction Products Nigeria Limited was incorporated under the Company Laws of the Federal Republic of Nigeria in April 5th 1994 as an Engineering Construction Firm",
    date: "05",
    month: "Apr",
    year: "1994",
  },
];

const achievements2 = [
  {
    title: "Won a prize",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "01",
    month: "Oct",
    year: "2013",
  },
];

var bgimg1 = require("./../../images/background/bg-map.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class Achievements1 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full p-t80 p-b50 sx-bg-secondry bg-no-repeat bg-bottom-center mobile-page-padding"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                >
                  <h3 className="sep-line-one">We Achieved</h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content ">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 m-b30">
                  {achievements1.map((item, index) => (
                    <div className="sx-box our-story text-white" key={index}>
                      <div className="mt-media our-story-info d-flex justify-content-start">
                        <div className="our-story-time">
                          <span className="date">{item.date}</span>
                          <span className="month">{item.month}</span>
                        </div>
                        <h4 className="text-uppercase our-story-year">
                          {item.year}
                        </h4>
                      </div>
                      <div className="our-story-detail">
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <div className="col-xl-6 col-lg-6 col-md-12">
                  {achievements2.map((item, index) => (
                    <div className="sx-box our-story text-white" key={index}>
                      <div className="mt-media our-story-info d-flex justify-content-start">
                        <div className="our-story-time">
                          <span className="date">{item.date}</span>
                          <span className="month">{item.month}</span>
                        </div>
                        <h4 className="text-uppercase our-story-year">
                          {item.year}
                        </h4>
                      </div>
                      <div className="our-story-detail">
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Achievements1;
