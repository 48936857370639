import React from "react";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";

var bgimg1 = require("./../../images/about-slider/asphalt.png");

class About4 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <h3 className="m-t0 m-b20 sx-tilte">
                      We operate as a multi-sector Asphalt company.
                    </h3>
                    <p>
                      We have three (3) Asphalt Production Plants established
                      for Commercial purposes. It is situated @ Ibadan, Ilorin &
                      Abuja. Our Quality Laboratory Department takes care of the
                      Mix Design and Quality Control during productions and
                      laying of asphalt. Our customers are Federal and State
                      Ministry & Private Construction Companies. The Asphalt
                      conform with the Federal Government General
                      Specifications. Our asphalt is of high quality and its
                      flexibility allows for easy application making it our
                      clients preferred choice.
                    </p>
                    {!this.props.isMine && (
                      <div className="text-left">
                        <NavLink to="/asphalt" className="site-button-link">
                          <span>Read More</span>
                        </NavLink>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content">
                          <div className="video-section-bottom">
                            <h3 className="sx-title text-white">
                              29 Years
                              <br />
                              Experience
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About4;
