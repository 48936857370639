import React, { useState } from "react";
const useSubmitContact = () => {
  const [errorObj, setErrorObj] = useState({
    error: false,
    success: false,
    isLoading: false,
    message: "",
  });

  const [formObj, setFormObj] = useState({
    name: "",
    email: "",
    phone: "",
    desc: "",
  });

  const inputHandle = (name) => {
    return (event) => {
      setFormObj((prevState) => ({ ...prevState, [name]: event.target.value }));
      setErrorObj({
        error: false,
        message: "",
        success: false,
        isLoading: false,
      });
    };
  };

  const submitForm = async () => {
    if (formObj.isLoading) return;
    if (!validateForm()) {
      setErrorObj({
        error: true,
        success: false,
        message: "Ensure form is properly filled, all inputs are required.",
        isLoading: false,
      });
      return;
    }

    try {
      setErrorObj((prevState) => ({
        ...prevState,
        isLoading: true,
        message: "Sending.....",
      }));
      const response = await fetch("https://catel.ng/cpn/contact.php", {
        method: "POST",
        body: JSON.stringify(formObj),
      });

      if (!response.ok) {
        throw new Error("Error");
      }

      setFormObj({
        name: "",
        email: "",
        phone: "",
        desc: "",
      });

      setErrorObj({
        error: false,
        success: true,
        message:
          "Message sent successfully, One of the team members will be in touch shortly. Thanks",
        isLoading: false,
      });
    } catch (er) {
      setErrorObj({
        error: true,
        success: false,
        message: "Unable to submit form, please try again later.",
        isLoading: false,
      });
    }
  };

  const validateForm = () => {
    return (
      formObj.name.length > 0 &&
      formObj.email.includes("@") &&
      formObj.phone.length > 0 &&
      formObj.desc.length > 0
    );
  };

  return [inputHandle, submitForm, errorObj, formObj];
};

export default useSubmitContact;
