import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home1 from "./Pages/Home";
import About1 from "./Pages/About";

import Services1 from "./Pages/Services";
import Error from "./Pages/Error";

import Projects from "./Pages/Projects";

import ProjectDetail from "./Pages/ProjectDetail";

import Faq from "./Pages/Faq";
import ContactUs from "./Pages/ContactUs";
import ScrollToTop from "./Common/ScrollToTop";
import Asphalt from "./Pages/Asphalt";

class Components extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <ScrollToTop />
        <div className="page-wraper">
          <Routes>
            <Route path="/" element={<Home1 />} />
            <Route path="/about" element={<About1 />} />
            <Route path="/services" element={<Services1 />} />
            <Route path="/error-404" element={<Error />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/asphalt" element={<Asphalt />} />
            <Route path="/projects/:path" element={<ProjectDetail />} />

            <Route path="/faq" element={<Faq />} />
            <Route path="/contact-us" element={<ContactUs />} />

            <Route path="*" element={<Error />} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default Components;
